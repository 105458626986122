// For using by legacy code after building with vite
// TODO Need refactoring

$(document).ready(function () {
  $('.brands-prod_li').hover(
    function (e) {
      const rel = $(this).attr('rel');

      if ($('.sliders_box[rel=' + rel + ']').length) {
        $(this).addClass('hover');
        $('.sliders_box[rel=' + rel + ']').addClass('hover');
      }
    },
    function (e) {
      const rel = $(this).attr('rel');
      $(this).removeClass('hover');
      $('.sliders_box[rel=' + rel + ']').removeClass('hover');
    }
  );

  $('.sliders_box').hover(
    function (e) {
      const rel = $(this).attr('rel');

      if ($('.brands-prod_li[rel=' + rel + ']').length) {
        $(this).addClass('hover');
        $('.brands-prod_li[rel=' + rel + ']').addClass('hover');
      }
    },
    function (e) {
      const rel = $(this).attr('rel');
      $(this).removeClass('hover');
      $('.brands-prod_li[rel=' + rel + ']').removeClass('hover');
    }
  );

  $('.stock_single-slider').slick({
    dots: true,
    arrows: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  });

  $('#tovarsmain .items').slick({
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1149,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  });

  $('#slider_main').slick({
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    lazyLoad: 'ondemand',
    slidesToScroll: 1,
    dots: true,
  });

  const $slickDots = $('#slider_main .slick-dots');
  $slickDots.css('margin-left', '-' + ($slickDots.width() / 2 - 4) + 'px');
});

function col() {
  let sumElWidth = 0;
  const menuWidth = $('.container').width();

  const $headerCol = $('.container .header_col');
  const elNum = $headerCol.length;

  $headerCol.each(function () {
    $(this).css({ padding: 0 });
    sumElWidth += $(this).width();
  });

  const deltaWidth = menuWidth - sumElWidth;

  $headerCol.css({ padding: '0px ' + Math.floor(deltaWidth / (2 * elNum)) + 'px' });
}

$(window).load(function () {
  $(window).resize();

  const $filter11 = $('#filter11');

  $filter11.text($('#box22 .filter_li.active label a').text());

  if ($filter11.length == 1) {
    $filter11.text($('.btt_box #box22 .qq1 ul li.active a').text());
  }
});

$(window).resize(function () {
  const body_w = $('body').width();

  col();

  const html = $('.brands_ul').html();

  if (body_w < 768) {
    if ($('.others_brands_box li').length == 0) {
      $('.others_brands_box').append(html);
    }
  }
});
